import React from "react";
import { Text, View, Pressable, ActivityIndicator } from "react-native";

const Button = ({ onPress, children, black, loading }) => {
  const blackButtonColors = {
    backgroundColor: "#000",
    textColor: "#fff",
    highlightColor: "#333",
  };

  const whiteButtonColors = {
    backgroundColor: "#fff",
    textColor: "#000",
    highlightTextColor: "#555",
    borderColor: "#D9D9D9",
  };

  if (loading) {
    return (
      <View
        style={{
          padding: 12,
          borderRadius: 10,
          paddingVertical: 8,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator
          size="small"
          color="#000"
        />
      </View>
    );
  } else {
    return (
      <Pressable
        onPress={onPress}
        style={({ pressed }) => {
          return {
            backgroundColor: black
              ? pressed
                ? blackButtonColors.highlightColor
                : blackButtonColors.backgroundColor
              : whiteButtonColors.backgroundColor,
            padding: 12,
            borderRadius: 10,
            paddingVertical: 8,
            justifyContent: "center",
            alignItems: "center",
            borderWidth: 1,
            borderColor: black ? "transparent" : whiteButtonColors.borderColor,
          };
        }}
      >
        {({ pressed }) => (
          <Text
            style={{
              color: black
                ? blackButtonColors.textColor
                : pressed
                ? whiteButtonColors.highlightTextColor
                : whiteButtonColors.textColor,
              fontSize: 16,
              fontWeight: "500",
              lineHeight: 24,
              letterSpacing: 0.1,
            }}
          >
            {children}
          </Text>
        )}
      </Pressable>
    );
  }
};

export { Button };
