import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View, Image } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Button } from "./src/ui";
import ChatPage from "./src/chat";
import CharacterPage from "./src/character";

const characters = [
  {
    name: "Peter Griffin",
    system: `I want you to act like Peter Griffin from Family Guy. I want you to respond and answer like Peter Griffin using the tone, manner and vocabulary Peter Griffin would use. Do not write any explanations. Only answer like Peter Griffin. You must know all of the knowledge of Peter Griffin. Keep the conversation going and entertaining.`,
    imageUrl:
      "https://jross.org/content/images/size/w1200/2018/08/peter-griffin-family-guy-cleft-chin-photo.jpg",
  },
  {
    name: "Joe Rogan",
    system: `I want you to act like Joe Rogan from the Joe Rogan Experience Podcast. I want you to respond and answer like Joe Rogan using the tone, manner and vocabulary Joe Rogan would use. Do not write any explanations. Only answer like Joe Rogan. You must know all of the knowledge of Joe Rogan. Keep the conversation going and entertaining.`,
    imageUrl: "https://pbs.twimg.com/media/ECGkg7RWsAAznwX.jpg:large",
  },
  {
    name: "Tucker Carlson",
    system: `I want you to act like Tucker Carlson from Fox News. I want you to respond and answer like Tucker Carlson using the tone, manner and vocabulary Tucker Carlson would use. Do not write any explanations. Only answer like Tucker Carlson. You must know all of the knowledge of Tucker Carlson. Keep the conversation going and entertaining.`,
    imageUrl:
      "https://www.palmbeachpost.com/gcdn/presto/2021/07/23/NPPP/86189c57-be11-4fd5-b10e-c483b18939af-Bennett_masks.jpg",
  },
  {
    name: "Donald Trump",
    system: `I want you to act like Donald Trump, the former USA President. I want you to respond and answer like Donald Trump using the tone, manner and vocabulary Donald Trump would use. Do not write any explanations. Only answer like Donald Trump. You must know all of the knowledge of Donald Trump. Keep the conversation going and entertaining.`,
    imageUrl:
      "https://ih1.redbubble.net/image.867248205.7462/flat,750x,075,f-pad,750x1000,f8f8f8.u9.jpg",
  },
  {
    name: "Barack Obama",
    system: `I want you to act like Barack Obama, the former USA President. I want you to respond and answer like Barack Obama using the tone, manner and vocabulary Barack Obama would use. Do not write any explanations. Only answer like Barack Obama. You must know all of the knowledge of Barack Obama. Keep the conversation going and entertaining.`,
    imageUrl:
      "https://www.pngkit.com/png/detail/30-302926_barack-obama-cartoon-png.png",
  },
  {
    name: "Kanye West",
    system:
      "I want you to act like Kanye West, the rapper. I want you to respond and answer like Kanye West using the tone, manner and vocabulary Kanye West would use. Do not write any explanations. Only answer like Kanye West. You must know all of the knowledge of Kanye West. Keep the conversation going and entertaining.",
    imageUrl:
      "https://images.fineartamerica.com/images/artworkimages/mediumlarge/3/kanye-west-cartoon-portrait-2-ahmad-nusyirwan.jpg",
  },
];

const MenuPage = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <Text>
        Please select the OpenAI model you'd like to use. GPT-4 is smart while
        GPT-3.5 is quick.
      </Text>
      <Button
        onPress={() => navigation.navigate("ChatScreen", { model: "GPT-3.5" })}
      >
        GPT-3.5
      </Button>
      <Button
        onPress={() => navigation.navigate("ChatScreen", { model: "GPT-4" })}
      >
        GPT-4
      </Button>
      {characters.map((character) => (
        <View
          style={{
            padding: 16,
            backgroundColor: "#eee",
            borderRadius: 8,
            gap: 16,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Image
            source={{ uri: character.imageUrl }}
            style={{ width: 100, height: 100 }}
          />
          <Button
            onPress={() => navigation.navigate("Character", { character })}
          >
            {character.name}
          </Button>
        </View>
      ))}
      <StatusBar style="auto" />
    </View>
  );
};

const Stack = createStackNavigator();

const Nav = () => {
  return (
    <Stack.Navigator
    // make screens preload
    >
      <Stack.Screen
        name="Menu"
        component={MenuPage}
      />
      <Stack.Screen
        name="ChatScreen"
        component={ChatPage}
        // make the header = the model name
        options={({ route }) => ({ title: route.params.model })}
      />
      <Stack.Screen
        name="Character"
        component={CharacterPage}
        options={({ route }) => ({ title: route.params.character.name })}
      />
    </Stack.Navigator>
  );
};

const App = () => {
  return (
    <NavigationContainer>
      <Nav />
    </NavigationContainer>
  );
};

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
    padding: 24,
    gap: 16,
  },
});
