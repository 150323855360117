import { Configuration, OpenAIApi } from "openai";

const apiKey = "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";

const configuration = new Configuration({
  apiKey: apiKey,
});
const openai = new OpenAIApi(configuration);

export default openai;
