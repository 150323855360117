import React, { useState, useCallback, useEffect } from "react";
import { Text, View, Alert } from "react-native";
import { GiftedChat, Bubble, InputToolbar } from "react-native-gifted-chat";
import uuid from "react-native-uuid";
import * as Clipboard from "expo-clipboard";

import { Button } from "./ui";
import openai from "./openai";

const SetHeader = ({ navigation, messageState }) => {
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View
          style={{
            paddingRight: 10,
          }}
        >
          <Button
            onPress={() => {
              const messageStateCopy = [...messageState];
              const clipboardArray = [];
              messageStateCopy.reverse().forEach((message) => {
                const authorTitle = message.user._id === 1 ? "User" : "AI";
                const currentLine = `${authorTitle}: ${message.text}\n\n`;
                clipboardArray.push(currentLine);
              });
              clipboardArray.unshift("Diji AI Conversation Log\n\n");
              const clipboardString = clipboardArray.join("");
              console.log(clipboardString);
              Clipboard.setStringAsync(clipboardString);
              alert("Copied to clipboard!");
            }}
          >
            Copy
          </Button>
        </View>
      ),
    });
  });
  return <></>;
};

const giftedSyntaxToApiSyntax = (jsonData) => {
  const messages = jsonData.reverse().map((item) => {
    const role =
      item.user._id === 1
        ? "user"
        : item.user._id === 2
        ? "assistant"
        : "system";
    return {
      role,
      content: item.text,
    };
  });
  console.log(messages);

  const systemPrompt = `I want you to act like Peter Griffin from Family Guy. I want you to respond and answer like Peter Griffin using the tone, manner and vocabulary Peter Griffin would use. Do not write any explanations. Only answer like Peter Griffin. You must know all of the knowledge of Peter Griffin. Keep the conversation going and entertaining.`;
  messages.unshift({
    role: "system",
    content: systemPrompt,
  });
  return messages;
};

const App = ({ route, navigation }) => {
  const [messageState, setMessageState] = useState([]);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const gptModel = route.params.model !== "GPT-4" ? "gpt-3.5-turbo" : "gpt-4";

  const onSend = useCallback((messages = []) => {
    setMessageState((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
  }, []);

  const sendToApi = async (messages) => {
    try {
      setAwaitingResponse(true);
      const completion = await openai.createChatCompletion({
        model: gptModel,
        messages: messages,
      });
      console.log(completion.data.choices[0].message);
      const response = completion.data.choices[0].message;
      const randomArray = ["This needs to be refactored"];
      const newArray = randomArray.map((message) => ({
        _id: uuid.v4(),
        createdAt: new Date(),
        text: response.content,
        user: { _id: 2 },
      }));
      setMessageState((previousMessages) =>
        GiftedChat.append(previousMessages, newArray)
      );
    } catch (error) {
      console.log(error);
      alert("An error occurred. Please try again later.");
    } finally {
      setAwaitingResponse(false);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#fff",
      }}
    >
      <SetHeader
        navigation={navigation}
        messageState={messageState}
      />
      <GiftedChat
        renderBubble={(props) => {
          return (
            <Bubble
              {...props}
              textStyle={{
                left: {
                  marginLeft: 0,
                  marginRight: 0,
                },
                right: {
                  marginLeft: 0,
                  marginRight: 0,
                },
              }}
              wrapperStyle={{
                left: {
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingVertical: 2,
                },
                right: {
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingVertical: 2,
                },
              }}
            />
          );
        }}
        keyboardShouldPersistTaps="never"
        renderFooter={() => {
          return (
            <>
              {awaitingResponse && (
                <View
                  style={{
                    padding: 8,
                  }}
                >
                  <Text>Awaiting response...</Text>
                </View>
              )}
              {messageState.length === 0 && (
                <View
                  style={{
                    padding: 8,
                  }}
                >
                  <Text>Please enter a message to get started</Text>
                </View>
              )}
            </>
          );
        }}
        renderTime={() => null}
        renderAvatar={null}
        messages={messageState}
        renderInputToolbar={(props) => (
          <InputToolbar
            {...props}
            containerStyle={{ borderTopColor: "#eee" }}
          />
        )}
        onSend={(messages) => {
          onSend(messages);
          const updatedMessagesObject = GiftedChat.append(
            messageState,
            messages
          );
          const apiMessages = giftedSyntaxToApiSyntax(updatedMessagesObject);
          console.log(apiMessages);
          sendToApi(apiMessages);
        }}
        user={{
          _id: 1,
        }}
      />
    </View>
  );
};

export default App;
